const awsconfig = {
  API: {
    endpoints: [
      {
        name: 'AssignmentAPI',
        endpoint: 'https://2aqee18ak1.execute-api.ap-northeast-1.amazonaws.com/v1',
        region: 'ap-northeast-1'
      }
    ]
  },
  Auth: {
    identityPoolId: 'ap-northeast-1:ae97bf14-ff2e-4da3-a90a-e51c9327acfb',
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_0ZIO1uLa5',
    userPoolWebClientId: 'svkp5eeciqfsqatk3im76bhhc',
    mandatorySignIn: false,
  }
}

export default awsconfig
