import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private translateServ: TranslateService
  ) { }

  async initializeApp(): Promise<void> {
    this.initTranslate();
  }

  private initTranslate(): void {
    // Set the default language for translation strings, and the current language.
    this.translateServ.setDefaultLang('ja');

    if (this.translateServ.getBrowserLang() !== undefined) {
      this.translateServ.use(this.translateServ.getBrowserLang()!);
    } else {
      this.translateServ.use('ja'); // Set your language here
    }
  }
}
