import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { provideHttpClient, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Amplify } from '@aws-amplify/core';

import awsconfig from './aws-exports';

import { AppService } from './app/app.service';

if (environment.production) {
  enableProdMode();
  disableConsole();
}

Amplify.configure(awsconfig);

export function initializeApp(appService: AppService) {
  return () => appService.initializeApp();
}

export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      })
    ]),
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true
    }),
    provideRouter(routes, withPreloading(PreloadAllModules)),
  ],
});

function disableConsole() {
  if (console) {
    console.log = () => { };
    console.debug = () => { };
    console.warn = () => { };
    console.info = () => { };  
  }
}
